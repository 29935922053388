import React, { useState } from 'react'

function Cards({data}) {
    const [c, setC] = useState(0);

    function slideshow(i) {
      setC(i);
    }
    
    
  return (
    <>
    <div className="h-full mx-auto flex flex-row items-center  overflow-x-hidden flex-nowrap">
      {data.map((item, index) => (
        <div key={index} className="card " style={{transform:`translateX(-${c * 100}%)`}}>
          <img src={item.imageUrl} alt="" className="w-1/3 min-w-[200px]" />
          <div className="flex flex-col gap-2 items-baseline justify-evenly"><div className="text-black text-center text-lg font-extrabold pb-5">{item.heading}</div><div>{item.description}</div></div>
        </div>
      ))}
    </div>
    <div className="text-center py-2 flex flex-row gap-2 justify-center">
    {data.map((item, index) => (
      <input type="radio" name="page" className="text-green-900"id="" value={index} onClick={() => slideshow(index)}/>
    ))}
     </div>
     </>
  )
}

export default Cards