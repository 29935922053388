import React, { useEffect , useState} from 'react'
import Form from './Form.js'
import { useParams } from 'react-router-dom';
import {courseRef} from './firebase.js'
import {getDoc, doc} from 'firebase/firestore'
function Product() {
    useEffect(() => {
        
        window.scrollTo(0, 0);
      }, []);

      const [successMessageVisible, setSuccessMessageVisible] = useState(false);
      
      let {name}= useParams()
      const [data, setData] = useState([]);
      useEffect(() => {
        const fetchData = async () => {
            try {
                const docRef = doc(courseRef, name);
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                  setData(docSnapshot.data()); // Extract data from the snapshot
                } else {
                  // Handle the case where the document doesn't exist
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }
        };
        fetchData();
    }, [name]);
  // Callback function to update success message
  const successMsg = () => {
    setSuccessMessageVisible(true);
    setTimeout(() => {
        setSuccessMessageVisible(false);
      }, 5000);
  };
  return (
    <main className="bg-green-100 ">
     <div id="successMessage" className={`text-green-600 ${successMessageVisible ? '' : 'hidden'} text-center sticky top-0 right-4 p-2 bg-white font-semibold rounded shadow-lg`}>
      Form submitted successfully!
  </div>
{/* <!-- product name----------------------------------------------------------------------------------------------------- --> */}

      <section name="Health care Courses" className="pb-8 ">
          <div className="md:w-2/3 mx-auto h-full flex flex-col gap-8 justify-center ">
            <h2 className="text-black text-center text-3xl font-extrabold p-5">{data.heading}</h2>
            <div className=" mx-auto flex md:flex-row flex-col md:gap-4 gap-8 items-center">
            
              <img src={data.imageUrl} alt="" className='w-[40%] min-w-[200px]'/>
              
              <div className='text-black w-[80%] font-bold text-xl'>
              {data.description}
              </div>
              
            </div>
            <div className="md:w-1/3 text-center"><a href="#register" className="btn">Register Now</a></div>
          </div>
      </section>

      {/* <!-- About Us------------------------------------------------------------------------------------------------ --> */}

      <section name="About Us" className="sm:h-[90vh] h-fit">
          {/* <h2 className="text-black text-center text-2xl font-extrabold p-5"></h2> */}
          <div className="w-2/3 h-full mx-auto flex items-center">
              <div className="shadow-none text-black text-lg font-semibold pb-10 pt-3 flex flex-col gap-4 items-center">
                  <ul className="list-disc">
                      <li className="pb-3"><span className=" font-extrabold">{data.time}-Day One-on-One Sessions</span> - You'll have the opportunity to engage in personalized one-on-one sessions with our experienced healthcare professionals. They will guide you through the process, answer your questions, and address your specific concerns</li>
                      <li className="pb-3"><span className=" font-extrabold">Lifetime Assistance through WhatsApp</span> - Our support doesn't end when the course does. You'll have access to our experts for a lifetime of assistance on WhatsApp. Any questions or concerns you may have, we're just a message away</li>
                      <li className="pb-3"><span className=" font-extrabold">Ayurvedic medications conveniently delivered to your doorstep</span> - We provide a supply of Ayurvedic medications tailored to your needs, conveniently delivered to your doorstep</li>
                      <li className="pb-3"><span className=" font-extrabold">Follow-Up One-on-One Sessions Available</span>- Need more guidance? You can opt for additional one-on-one sessions at just @ 250 each. We're here to ensure your journey is as comfortable and successful as possible</li>
                      <li className="pb-3"><span className=" font-extrabold">Everything @{data.price}/ only</span>- All of this, including the course, {data.time}-day sessions, Ayurvedic medications, lifetime support on WhatsApp, and the option for extra one-on-one sessions, is available for a single payment of {data.price}</li>
                  </ul>
              </div>
          </div>
      </section>

      {/* <!-- How we care-------------------------------------------------------------------------------------------- --> */}

      <section name="About Us" className="">
          <h2 className="text-black text-center text-2xl font-extrabold p-5">How Can Inspire Medications Transform Your Health?</h2>
          <div className="w-2/3 h-full mx-auto">
              <div className="text-black text-lg font-semibold pb-10 pt-3 ">
                  <ul className="list-disc">
                      <li className="pb-3">Ayurvedic medications delivered directly to your doorstep for hassle-free healthcare management.</li>
                      <li className="pb-3">Access to experienced healthcare professionals for personalized medical counseling.</li>
                      <li className="pb-3">Personalized consultations with our experts to address your specific healthcare needs.</li>
                      <li className="pb-3">Immediate access to emergency sessions for critical healthcare situations.</li>
                      <li className="pb-3">All our treatments are based on the ancient and holistic principles of Ayurveda.</li>
                      <li className="pb-3">Personalized consultations with our experts to address your specific healthcare needs.</li>
                  </ul>
              </div>
          </div>
      </section>

      {/* <!-- Form--------------------------------------------------------------------------------------------------- --> */}
      <section name="Register" className="p-5 " id="register">
          <Form successMsg={successMsg} Course={data.heading}/>
      </section>
{/*----------- How To register ---------------------------------------------------------------------------------------*/}
      <section name="Register" className="">
          <h2 className="text-black text-center text-2xl font-extrabold p-5">How to Register</h2>
          <div className="w-2/3 h-full mx-auto">
              <div className="text-black font-semibold text-lg pb-10 pt-3 ">
                  <ol className="list-decimal">
                      <li className="pb-3"><span className=" font-extrabold">Enter Your Information: </span> Provide your name and email address</li>
                      <li className="pb-3"><span className=" font-extrabold">Select Your Course:</span> Choose the course you wish to register for.</li>
                      <li className="pb-3"><span className=" font-extrabold">Describe Your Problem (Optional):</span> If you have specific health concerns or questions, you can provide a brief description here.</li>
                      <li className="pb-3"><span className=" font-extrabold">Make Payment:</span> Complete the payment for the selected course by scanning the provided QR code.</li>
                      <li className="pb-3"><span className=" font-extrabold">Submit UTR Details:</span> Fill out the payment UTR (Unique Transaction Reference) provided to you during the payment process.</li>
                      <li className="pb-3"><span className=" font-extrabold">We'll Reach Out to You:</span> After successful registration, our team will get in touch with you to confirm your course and address any additional needs or concerns.</li>
                  </ol>
              </div>
          </div>
      </section>
  </main>
  )
}

export default Product
