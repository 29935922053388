import Navbar from './Navbar.js'
import Footer from './Footer.js'
import Home from './Home.js'
import Product from './Product.js'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="App">
      
      <Router>

      <Navbar/>

        <Routes>
          <Route path="/" element={<Home />}/>
           <Route path="/:name" element={<Product/>}/>
           <Route path="/*" element="The page doesnt exist"/>"
        </Routes>

        <Footer/>
        
      </Router>
      
      
    </div>
  );
}

export default App;
