import React from 'react'
import Cards from './cards.js'
import Courses from './Courses.js'
function Home() {
    
    const sliderData = [
        {
            heading: "Acidity & and other abdomen disorders",
          imageUrl: 'Acidity.jpg',
          description: 'Acid peptic disorder , oftenly known as Acidity is generally observed due to change in socio economic conditions of an individual .',
        },
        {
            heading: "Neuropathy",
            imageUrl: '3.jpeg',
            description: `
            Where a person performs is routine activity for a long time in sitting or lying posture , when he tries to stand or try to change his posture , he feels numbness in upper limbs / lower limbs .
            This feeling is unbearable and occurs for nearly 3 to 6 minutes or sometimes more.
            It's major cause is deficiency of vitamin D
            And vitamin B6 or this functionality of nerves.
            Patients observed in neuropathy are with diabetes or sometimes without diabetes.
            Both types can be handled very well with our treatment part .`,
          },
          {
            heading: "Gynaec Disorders like Dysmenorrhoea",
            imageUrl: '5.jpeg',
            description: `It is observed that in teen age ( 13 to 19 years ) maximum females suffer from various following disorders due to puberty of age harmony changes in the body
            
            - Sposms / pain in
            - irritability
            - Headache
            - Bodyache
            - Nausea / Vomating
            - scanty menses or delay in menstrual cycle`
          },
          {
            heading: "Removal of Kidney and Liver Stones Without Surgery",
            imageUrl:'9.jpeg',
            description:`It is commonly believed that kidney and liver (Gall Bladder) stones require surgical procedures for removal.
            However, our unique approach to treatment offers the possibility of reducing stone size and eventually dissolving them naturally. We specialize in the non-surgical removal of stones ranging from 8 mm to 11 mm. This is achieved through lifestyle modifications and the use of essential medications.`
          },
          {
            heading: 'Immune disorders like thyroid , blood pressure , and diabetes',
            imageUrl: '7.jpeg',
            description:`It is observed that 4 among 10 persons in society suffer from diseases like diabetes , blood pressure and thyroid . The main cause is changed in diet schedule due to luxurious life.
            We assure an excellent approach and modification of lifestyle with the help of diet and yoga for such types .`
          }
        // Add more data items as needed
      ];
        
  return (
    <main className="bg-green-100 flex flex-col gap-5">
    <section name="Inspire medication" className="hero h-[85vh] w-full">
        <div className="hero-fnt h-full md:w-[40%] p-7 mx-auto my-auto flex  flex-col gap-4 justify-center">
            <h1 className="md:text-4xl text-2xl text-green-800 font-serif font-black">INSPIRE MEDICATIONS</h1>
            <div className="text-right md:text-2xl text-xl text-green-800">- Your source for a better life</div> 
            <ul className="list-disc pl-5 md:text-2xl text-lg text-green-800">
                <li>One-to-One Sessions</li>
                <li>Meds delivered home</li>
                <li>Everything in your comfort zone</li>
            </ul>
            <div className='w-full text-center pt-3'>
            <a className="btn w-fit cursor-pointer" href="#services">View Courses</a>
            </div>
        </div>
    </section>

    {/* <!-- About Expert ------------------------------------------------------------------------------------------------ --> */}

    <section name="About Expert" className="h-fit p-5">
      <h2 className="text-black text-center text-3xl font-extrabold p-5">Our Expert</h2>
      <div className='sm:w-[75%] w-full  mx-auto'>
        <div className="h-full flex md:flex-row flex-col gap-5 items-center">
          <div className='flex flex-col items-center justify-center gap-3'>
            <img src="Expert_photo.jpg" alt="Dr Amar G Kartari" className=" w-[90%] min-w-[200px] object-center rounded-xl"/>
            <div className='w-fit'>
            <h3 className="text-black text-center text-xl font-bold">Dr Amar G Kartari <br/> </h3>
            Ayurvedic Consultant, Inspire Medications
            </div>
            </div>
          <div className="bubble flex flex-col gap-4 md:w-2/3 items-center">
             
            <div className="px-4 ">
              Hello  ! 
              I am Dr Amar G Kartari an Ayurvedic Consultant from  Amaravati, Maharashtra.
              <br/>
              <ul className="list-disc">
            <li>Ex - Housing Officer at Nagrik Hospital , Nagpur</li>
            <li>Ex - Residence Medical Officer at Arogya Niketan , Vasai, Mumbai
           </li> <li>Ex - Govt . Medical Officer ( Rural ) in Wardha District of Maharashtra
   </li>         <li>Current Emergency Medical Officer at Maharashtra Emergency Medical Services</li>
          </ul>
          </div>
         </div>
        </div>
        
        </div>
    </section>

    {/* <!-- About Us----------------------------------------------------------------------------------------------------- --> */}

    <section name="About Us" className="pb-5" id="aboutUs">
        <h2 className="text-black text-center text-2xl font-extrabold p-5">About Us</h2>
        <div className="md:w-2/3 h-full mx-auto bubble ">
            <div className="shadow-none p-5 text-black font-semibold text-sm md:text-lg pt-3 flex flex-col gap-4 "><p><span className='font-bold'>Inspire Medications</span>, under the visionary leadership of <span className='font-bold'> Dr. Amar G Kartari</span>, embodies a commitment to your well-being. We proudly offer a comprehensive suite of online medical counseling services, paired with the convenience of delivering essential medications directly to your doorstep.</p>
              <p>Our mission is to ensure that you receive the highest level of care, all from the comfort of your home. With us, you can seamlessly schedule online appointments with our team of expert healthcare professionals.
              </p>
             <p>Your health and convenience are paramount to us, and we take pride in making sure you can access all the support you need without ever having to leave your home.</p> 
             <p>Best Wishes 🙏</p>
                <p>Happy Health!</p>
             </div>
            
        </div>
        {/* <!-- HEalthcare---------------------------------------------------------------------------------------------- --> */}
    </section>

    <section name="Health care Courses" className="p-5 h-fit " id="services">
          <h2 className="text-black text-center text-2xl font-extrabold pb-5"> Our Healthcare Courses</h2>
          <div className="bg-white md:w-2/3 mx-auto rounded-lg shadow-lg">
          <Courses/>
        </div>
    </section>

    {/* <!-- Speacialities----------------------------------------------------------------------------------------------- --> */}

    <section name="Specialities" className="p-5 h-fit">
        <h2 className="text-black text-center text-2xl font-extrabold pb-5">Our Specialities</h2>
        <div className="bg-white md:w-2/3 mx-auto rounded-lg shadow-lg pt-5">
      <Cards data={sliderData} />
        </div>
    </section>

    <section name="About Us" className="pb-5 md:h-fit" id="Webinar">
        <h2 className="text-black text-center text-2xl font-extrabold p-5">Discover Our commitment to Your Health - Join Our Free Webinar </h2>
        <div className=" h-[50vh] mx-auto  ">
            <div className="shadow-none h-full p-5 text-black font-semibold  text-lg pt-3 flex md:flex-row-reverse flex-col gap-4 items-center justify-center">
            <img src="16.jpg" alt="Webinar" className="w-[40%] min-w-[200px] object-center rounded-xl"/>
              <div className='flex flex-col md:w-[60%] gap-8'>
                
              <span className='font-bold  md:text-2xl text-base'>Unlock the secrets to your well-being!
Join our free webinar and get a closer look at how Inspire Medications can empower your health journey. Register now to discover the care and support you deserve </span><div className='w-full text-center'> <a className="btn w-fit cursor-pointer" href="https://docs.google.com/forms/d/e/1FAIpQLSeOmUXM-2ofFbMTGtw-nJvpEqCtYcdwP6xhDUrcHMKcwDFbog/viewform"  rel="noreferrer" target="_blank">Join Now</a></div>
</div>


</div>
            
        </div>
    </section>
</main>
  )


  
}

export default Home
