import React, {useState} from 'react'
import { getDatabase, ref, set} from 'firebase/database'
import {app} from './firebase.js'
function Form({successMsg, Course}) {
    
    const formDB= getDatabase(app)
    const [formData, setformData]=useState({
      name: "",
      email: "",
      mobileNo: "",
      course: "",
      description: "",
      paymentUTR: "",
    })
    let name, value;
    const userData=(e)=>{
        name=e.target.name
        value=e.target.value
        setformData({...formData , [name]:value})
    }
    const submitForm=(e)=>{
        e.preventDefault()
        console.log(formData)
        set(ref(formDB, formData.name), {
            name: formData.name,
            email: formData.email,
            mobileNo: formData.mobileNo,
            course: Course,
            description: formData.description,
            paymentUTR: formData.paymentUTR,
          });
         
        setformData({
            name: "",
            email: "",
            mobileNo: "",
            course: "",
            description: "",
            paymentUTR: "",
        });
        successMsg()
    }
  return (
    <div className="md:w-[75%] mx-auto h-full flex flex-col justify-center items-center">
       
            <h2 className="text-black text-center text-2xl font-extrabold pb-5">Registeration Form</h2>
            <div className='flex md:flex-row-reverse flex-col gap-4'>
            <img src="QR_code.png" className='md:w-[40%] w-full' alt="QR code"/>
              <form action=""  className="md:w-[55%]" onSubmit={submitForm} id="form">
                  
              <input type="text"  className="p-2 border-green-700 rounded-lg border-2 w-full" id="name" name="name" placeholder="Full Name*" value={formData.name} onChange={userData} required/><br/><br/>

              
              <input type="tel" className="p-2 border-green-700 rounded-lg border-2 w-full"  id="number" name="mobileNo" placeholder="Mobile No.*" minlength="10" value={formData.mobileNo} onChange={userData} required/><br/><br/>

              
              <input type="email" className="p-2 border-green-700 rounded-lg border-2 w-full"  id="email" name="email" placeholder="Email*" value={formData.email} onChange={userData} required/><br/><br/>


              
              <textarea id="description" className="p-2 border-green-700 rounded-lg border-2 w-full" name="description" placeholder="Description" rows="4" cols="50" value={formData.description} onChange={userData}></textarea><br/><br/>

              <input type="text" className="p-2 border-green-700 rounded-lg border-2 w-full" name="paymentUTR" id="payment" placeholder="Payment UTR*" value={formData.paymentUTR} onChange={userData} required/>

              <input type="submit" className="btn mt-5 w-full cursor-pointer" value="Submit"/>

              </form>
                         
            </div>
          </div>
  )
}

export default Form