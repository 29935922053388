import React from 'react'
import { Link } from 'react-router-dom';
import './App.css';

function Navbar() {
  return (
    <div>
      <div className='w-full bg-green-700 h-[15px]'></div>
      <nav className="p-3 w-100 flex flex-row">
    <div className="logo flex sm:mx-0 mx-auto">
      <Link to="/" className="flex flex-row  items-center sm:justify-start justify-center h-full font-sans font-bold">
        <img src="/Just_logo.png" alt="Inspire" width="17%"></img> <div>INSPIRE-MEDICATIONS </div>
        </Link>
    </div>
    <div className="md:flex hidden ml-auto flex-row items-center gap-4">
        <a className="btn cursor-pointer" href="#aboutUs">About us</a>
        <a className="btn cursor-pointer" href="#services">Our Courses</a>
        
    </div>
</nav></div>
  )
}

export default Navbar