import React from 'react'

function Footer() {
  return (
    <footer className="text-gray-600 body-font">
    
    <div className="bg-green-800">
      <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p className="text-white text-sm text-center sm:text-left">
          <a href="mailto:inspiremedications@gmail.com" rel="noopener noreferrer" className="text-white ml-1" target="_blank">inspiremedications@gmail.com</a>
        </p>
        <span className="inline-flex gap-2 sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <a href="tel:+918275314967" className='text-white font-bold'>+91-8275314967</a>
          <a className="text-white" href='https://www.facebook.com/profile.php?id=61553115938733' rel="noreferrer" target="_blank">
            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          
          <a className="ml-3 text-white" href='https://instagram.com/inspiremedications?utm_source=qr&igshid=ZTM4ZDRiNzUwMw==' rel="noreferrer" target="_blank">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          
          <a className="ml-3 text-white" href='https://youtube.com/@inspiremeadications?si=Q3DtKdpblpLY0TqP' rel="noreferrer" target="_blank">
          <svg fill="none" className='w-5 h-5' version="1.1" id="Capa_1"  viewBox="0 0 49 49" stroke="currentColor" stroke-width="2.5"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M39.256,6.5H9.744C4.371,6.5,0,10.885,0,16.274v16.451c0,5.39,4.371,9.774,9.744,9.774h29.512 c5.373,0,9.744-4.385,9.744-9.774V16.274C49,10.885,44.629,6.5,39.256,6.5z M47,32.726c0,4.287-3.474,7.774-7.744,7.774H9.744 C5.474,40.5,2,37.012,2,32.726V16.274C2,11.988,5.474,8.5,9.744,8.5h29.512c4.27,0,7.744,3.488,7.744,7.774V32.726z"></path> <path d="M33.36,24.138l-13.855-8.115c-0.308-0.18-0.691-0.183-1.002-0.005S18,16.527,18,16.886v16.229 c0,0.358,0.192,0.69,0.502,0.868c0.154,0.088,0.326,0.132,0.498,0.132c0.175,0,0.349-0.046,0.505-0.137l13.855-8.113 c0.306-0.179,0.495-0.508,0.495-0.863S33.667,24.317,33.36,24.138z M20,31.37V18.63l10.876,6.371L20,31.37z"></path> </g> </g> </g></svg>
          </a>
        </span>
      </div>
    </div>
  </footer>
  )
}

export default Footer