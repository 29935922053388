
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {getDocs} from "firebase/firestore"
import {courseRef} from './firebase.js'
function Courses() {
    const [i, setI] = useState(0);

    function slideshow(index) {
      setI(index);
    }
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const querySnapshot = await getDocs(courseRef);
            const courseData = [];
            querySnapshot.forEach((doc) => {
              // Access the data for each document using doc.data()
              courseData.push(doc.data());
            });
            setData(courseData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);
    
  return (
    <>
    <div className="h-fit mx-auto flex flex-row items-center pt-5 overflow-x-hidden flex-nowrap">
      {data && data.map((item, index) => (
        <div key={index} className="card md:h-[60vh]" style={{transform:`translateX(-${i * 100}%)`}}>
          <img src={item.imageUrl} alt="" className="w-1/3 min-w-[200px]" />
          <div className="flex flex-col gap-5 items-baseline justify-evenly"><div className="text-black text-center text-lg font-extrabold pb-3">{item.heading}</div>
          <div>
            <ul className='list-disc px-7'><li>Ayurvedic medications conveniently delivered to your doorstep.</li><li> {item.time}-Day One-on-One Sessions </li><li>Lifetime Assistance through WhatsApp, Everything <span className='font-bold'>@{item.price}/- only</span></li></ul></div>
          <div className="mx-auto p-5"><Link to={`/${item.URL}`} className="btn ">Register</Link></div></div>
          
        </div>
      ))}
    </div>
    <div className="w-full  py-2 flex flex-row gap-2 justify-center">
    {data && data.map((item, index) => (
      <input type="radio" name="page" className=""id="" value={index} onClick={() => slideshow(index)}/>
    ))}
     </div>
     </>
  )
}

export default Courses
