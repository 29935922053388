import { initializeApp } from 'firebase/app'
import { getFirestore, collection} from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBosQ7LnoXDOhWTkPOHZy079DzvOq7mJf4",
    authDomain: "inspire-54d7a.firebaseapp.com",
    databaseURL: "https://inspire-54d7a-default-rtdb.firebaseio.com",
    projectId: "inspire-54d7a",
    storageBucket: "inspire-54d7a.appspot.com",
    messagingSenderId: "71841596867",
    appId: "1:71841596867:web:90c84a0940d852263b6976",
    measurementId: "G-SJWRL2XN79"
  };
  const app = initializeApp(firebaseConfig);

  const fire=getFirestore(app)
  const courseRef=collection(fire, 'Courses');

export {app, courseRef}
